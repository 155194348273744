import React from 'react';
import styled from 'styled-components';
import {Input, Select, Checkbox, DatePicker, Radio} from 'antd';

import {CODE, CITY} from '../../../content/address';
import {USER_LABELS} from '../../../content/labels';
import {ImageEditField} from './MemberResume.ImageField';
import {ImagesEditField} from './MemberResume.ImagesField';

function ResumeForm(props) {
  const {
    record,
    getInputProps,
    getCheckboxProps,
    getSelectProps,
    getDatePickerProps,
    getDateRangePickerProps,
    getRadioGroupProps,
  } = props;

  if (!record) {
    return null;
  }

  return (
    <Wrapper>
      <section>
        <h3>基本資訊</h3>
        <div className="field">
          <label>
            <span className="required">*</span>姓名
          </label>
          <Input {...getInputProps('name')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>
            國籍
          </label>
          <Checkbox {...getCheckboxProps('nationality')}>本國籍</Checkbox>
          <Checkbox {...getCheckboxProps('nationality', false)}>
            外國籍
          </Checkbox>
          <Input placeholder="請填寫國家" {...getInputProps('country')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>
            身分證字號 / 居留證號 / 護照號碼
          </label>
          <Input disabled {...getInputProps('id_number')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>性別
          </label>
          <Checkbox {...getCheckboxProps('gender')}>男</Checkbox>
          <Checkbox {...getCheckboxProps('gender', false)}>女</Checkbox>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>出生日期
          </label>
          <DatePicker {...getDatePickerProps('birthday')} />
        </div>

        <div className="field">
          <label>年齡</label>
          <Input disabled type="number" {...getInputProps('age')} />
        </div>

        <div className="field">
          <label style={{width: 180}}>是否為身心障礙者？</label>
          <Checkbox {...getCheckboxProps('disability', false)}>否</Checkbox>
          <Checkbox {...getCheckboxProps('disability')}>是</Checkbox>
        </div>

        <div className="field" style={{alignItems: 'flex-start'}}>
          <label>身心障礙人士證明</label>
          <ImageEditField fieldname="disability_credentials" record={record} />
        </div>

        <div className="field">
          <label>戶籍地址</label>

          <div className="select-wrapper">
            <Select
              placeholder="區號"
              style={{width: 70}}
              {...getSelectProps('permanent_address_code')}>
              {CODE.map((c) => (
                <Select.Option value={c.code}>{c.code}</Select.Option>
              ))}
            </Select>
          </div>

          <div className="select-wrapper">
            <Select
              placeholder="縣市"
              style={{width: 100}}
              {...getSelectProps('permanent_address_city')}>
              {CITY.map((city) => (
                <Select.Option value={city}>{city}</Select.Option>
              ))}
            </Select>
          </div>
          <Input
            placeholder="詳細地址"
            {...getInputProps('permanent_address_detail')}
          />
        </div>

        <div className="field">
          <label>現居地址</label>

          <div className="select-wrapper">
            <Select
              placeholder="區號"
              style={{width: 70}}
              {...getSelectProps('residence_address_code')}>
              {CODE.map((c) => (
                <Select.Option value={c.code}>{c.code}</Select.Option>
              ))}
            </Select>
          </div>

          <div className="select-wrapper">
            <Select
              placeholder="縣市"
              style={{width: 100}}
              {...getSelectProps('residence_address_city')}>
              {CITY.map((city) => (
                <Select.Option value={city}>{city}</Select.Option>
              ))}
            </Select>
          </div>
          <Input
            placeholder="詳細地址"
            {...getInputProps('residence_address_detail')}
          />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>室內電話
          </label>
          <Input
            maxLength={12}
            placeholder="02-0000-0000"
            {...getInputProps('phone_home')}
          />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>手機號碼
          </label>
          <Input
            maxLength={12}
            placeholder="0910-000-000"
            {...getInputProps('phone')}
          />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>電子郵件
          </label>
          <Input placeholder="test@gmail.com" {...getInputProps('email')} />
        </div>

        <div className="field">
          <label>Line ID</label>
          <Input placeholder="test" {...getInputProps('line')} />
        </div>

        <div className="field">
          <label>身高</label>
          <Input
            type="number"
            min={0}
            suffix="公分"
            {...getInputProps('height')}
          />
        </div>

        <div className="field">
          <label>體重</label>
          <Input
            type="number"
            min={0}
            suffix="公斤"
            {...getInputProps('weight')}
          />
        </div>

        <div className="field">
          <label>兵役狀況</label>
          <Select
            style={{width: 100}}
            {...getSelectProps('military_service_state')}>
            <Select.Option value="未役">未役</Select.Option>
            <Select.Option value="已役">已役</Select.Option>
            <Select.Option value="免服">免服</Select.Option>
          </Select>
        </div>

        <div className="field">
          <label>駕照</label>

          <Select style={{width: 100}} {...getSelectProps('driver_license')}>
            <Select.Option value="無">無</Select.Option>
            <Select.Option value="機車">機車</Select.Option>
            <Select.Option value="汽車">汽車</Select.Option>
          </Select>
        </div>

        <div className="field">
          <label style={{width: 180}}>有無法定傳染疾病?</label>

          <Checkbox {...getCheckboxProps('notifiable_disease', false)}>
            否
          </Checkbox>

          <Checkbox {...getCheckboxProps('notifiable_disease')}>是</Checkbox>
        </div>

        <div className="field">
          <label>主要工作區域</label>
          <Input placeholder="台北、台中" {...getInputProps('location')} />
        </div>

        <div className="field" style={{alignItems: 'flex-start'}}>
          <label>個人近期照片</label>
          <ImageEditField fieldname="avatar" record={record} />
        </div>
      </section>

      <section>
        <h3>學歷背景</h3>

        <div className="field">
          <label>
            <span className="required">*</span>最高學歷
          </label>

          <div className="select-wrapper">
            <Select
              {...getSelectProps('highest_education_level')}
              style={{width: 200}}>
              <Select.Option value="博士">博士</Select.Option>
              <Select.Option value="碩士">碩士</Select.Option>
              <Select.Option value="大學">大學</Select.Option>
              <Select.Option value="專科">專科</Select.Option>
              <Select.Option value="高中">高中</Select.Option>
              <Select.Option value="國中以下">國中以下</Select.Option>
            </Select>
          </div>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>學歷狀況
          </label>

          <Checkbox {...getCheckboxProps('highest_education_state')}>
            畢業
          </Checkbox>
          <Checkbox {...getCheckboxProps('highest_education_state', false)}>
            在學
          </Checkbox>
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀學校
          </label>
          <Input {...getInputProps('highest_education_name')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀科系
          </label>
          <Input {...getInputProps('highest_education_department')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>就讀年級
          </label>
          <Input
            type="number"
            min={0}
            {...getInputProps('highest_education_grade')}
          />
        </div>

        <div className="field">
          <label>學校經驗</label>
          <Input.TextArea
            style={{resize: 'none', maxWidth: 460, height: 120}}
            {...getInputProps('education_experience')}
          />
        </div>
      </section>

      <section>
        <h3>家庭背景</h3>

        <div className="field">
          <label>
            <span className="required">*</span>緊急聯絡人
          </label>
          <Input {...getInputProps('emergency_contact')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>關係
          </label>
          <Input {...getInputProps('emergency_contact_relationship')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>手機號碼
          </label>
          <Input
            placeholder="0910-000-000"
            {...getInputProps('emergency_contact_phone')}
          />
        </div>

        <div className="field">
          <label>家中成員1</label>
          <Input {...getInputProps('family_member_1_name')} />
        </div>

        <div className="field">
          <label>手機號碼</label>
          <Input
            placeholder="0910-000-000"
            {...getInputProps('family_member_1_contact')}
          />
        </div>

        <div className="field">
          <label>家中成員2</label>
          <Input {...getInputProps('family_member_2_name')} />
        </div>

        <div className="field">
          <label>手機號碼</label>
          <Input
            placeholder="0910-000-000"
            {...getInputProps('family_member_2_contact')}
          />
        </div>
      </section>

      <section>
        <h3>工作經驗</h3>

        <div className="field">
          <label>公司1名稱</label>
          <Input {...getInputProps('company_1_name')} />
        </div>

        <div className="field">
          <label>公司1職務</label>
          <Input {...getInputProps('company_1_position')} />
        </div>

        <div className="field">
          <label>任職期間</label>
          <DatePicker.RangePicker
            picker="month"
            {...getDateRangePickerProps('company_1_duration')}
          />
        </div>

        <div className="field">
          <label>公司2名稱</label>
          <Input {...getInputProps('company_2_name')} />
        </div>

        <div className="field">
          <label>公司2職務</label>
          <Input {...getInputProps('company_2_position')} />
        </div>

        <div className="field">
          <label>任職期間</label>
          <DatePicker.RangePicker
            picker="month"
            {...getDateRangePickerProps('company_2_duration')}
          />
        </div>

        <div className="field">
          <label>公司3名稱</label>
          <Input {...getInputProps('company_3_name')} />
        </div>

        <div className="field">
          <label>公司3職務</label>
          <Input {...getInputProps('company_3_position')} />
        </div>

        <div className="field">
          <label>任職期間</label>
          <DatePicker.RangePicker
            picker="month"
            {...getDateRangePickerProps('company_3_duration')}
          />
        </div>

        <div className="field">
          <label>備註</label>
          <Input {...getInputProps('company_note')} />
        </div>
      </section>

      <section>
        <h3>語言能力</h3>

        <div className="field">
          <label>英文</label>
          <Radio.Group {...getRadioGroupProps('language_english')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>台語</label>
          <Radio.Group {...getRadioGroupProps('language_taiwanese')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>日語</label>
          <Radio.Group {...getRadioGroupProps('language_japanese')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>西語</label>
          <Radio.Group {...getRadioGroupProps('language_spanish')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>韓語</label>
          <Radio.Group {...getRadioGroupProps('language_korean')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>德語</label>
          <Radio.Group {...getRadioGroupProps('language_german')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>法語</label>
          <Radio.Group {...getRadioGroupProps('language_french')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>泰語</label>
          <Radio.Group {...getRadioGroupProps('language_thai')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>越南語</label>
          <Radio.Group {...getRadioGroupProps('language_vietnamese')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>馬來語</label>
          <Radio.Group {...getRadioGroupProps('language_malay')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>其他語言</label>
          <Input {...getInputProps('language_other_name')} />
        </div>

        <div className="field">
          <label>其他語言程度</label>
          <Radio.Group {...getRadioGroupProps('language_other_level')}>
            <Radio.Button value="無">無</Radio.Button>
            <Radio.Button value="略懂">略懂</Radio.Button>
            <Radio.Button value="普通">普通</Radio.Button>
            <Radio.Button value="中上">中上</Radio.Button>
            <Radio.Button value="精通">精通</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>語言備註</label>
          <Input {...getInputProps('language_note')} />
        </div>
      </section>

      <section>
        <h3>其他能力</h3>
        <div className="field">
          <label>電腦能力</label>
          <Radio.Group {...getRadioGroupProps('ability_computer')}>
            <Radio.Button value={true}>具備</Radio.Button>
            <Radio.Button value={false}>不具備</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>證照名稱</label>
          <Input {...getInputProps('ability_computer_name')} />
        </div>

        <div className="field">
          <label>{'證照證明\n(可多張)'}</label>
          <ImagesEditField fieldname="ability_computer_files" record={record} />
        </div>

        <div className="field">
          <label>中打能力</label>
          <Input
            type="number"
            suffix="字 / 分鐘"
            {...getInputProps('ability_chinese_typewriting')}
          />
        </div>

        <div className="field">
          <label>英打能力</label>
          <Input
            type="number"
            suffix="字 / 分鐘"
            {...getInputProps('ability_english_typewriting')}
          />
        </div>

        <div className="field">
          <label>英語檢定</label>
          <Radio.Group {...getRadioGroupProps('ability_english_certification')}>
            <Radio.Button value={true}>具備</Radio.Button>
            <Radio.Button value={false}>不具備</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>{'證照名稱\n(可複選)'}</label>
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            {...getSelectProps('ability_english_certification_name')}>
            <Select.Option value="全民英檢 GEPT">全民英檢 GEPT</Select.Option>
            <Select.Option value="多益 TOEIC">多益 TOEIC</Select.Option>
            <Select.Option value="托福 TOEFL">托福 TOEFL</Select.Option>
            <Select.Option value="雅思 IELTS">雅思 IELTS</Select.Option>
          </Select>
        </div>

        <div className="field">
          <label>{'證照證明\n(可多張)'}</label>
          <ImagesEditField
            fieldname="ability_english_certification_files"
            record={record}
          />
        </div>

        <div className="field">
          <label>其他專長</label>
          <Input {...getInputProps('expertise')} />
        </div>
      </section>

      <section>
        <h3>其他經驗</h3>
        <div className="field">
          <label>國外留學 / 工作經驗 1</label>
          <Radio.Group {...getRadioGroupProps('aboard1')}>
            <Radio.Button value={true}>具備</Radio.Button>
            <Radio.Button value={false}>不具備</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>國外留學 / 工作時間 1</label>
          <DatePicker.RangePicker
            picker="month"
            {...getDateRangePickerProps('aboard1_time')}
          />
        </div>

        <div className="field">
          <label>國外留學 / 工作國家 1</label>
          <Input {...getInputProps('aboard1_country')} />
        </div>

        <div className="field">
          <label>國外學習 / 工作項目 1</label>
          <Input {...getInputProps('aboard1_major')} />
        </div>

        <div className="field">
          <label>國外留學 / 工作經驗 2</label>
          <Radio.Group {...getRadioGroupProps('aboard2')}>
            <Radio.Button value={true}>具備</Radio.Button>
            <Radio.Button value={false}>不具備</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>國外留學 / 工作時間 2</label>
          <DatePicker.RangePicker
            picker="month"
            {...getDateRangePickerProps('aboard2_time')}
          />
        </div>

        <div className="field">
          <label>國外留學 / 工作國家 2</label>
          <Input {...getInputProps('aboard2_country')} />
        </div>

        <div className="field">
          <label>國外學習 / 工作項目 2</label>
          <Input {...getInputProps('aboard2_major')} />
        </div>

        <div className="field">
          <label>{'國外學習 / 工作證明\n(可多張)'}</label>
          <ImagesEditField fieldname="aboard_files" record={record} />
        </div>
      </section>

      <section>
        <h3>服裝餐飲詢問</h3>

        <div className="field">
          <label style={{width: 'auto', whiteSpace: 'pre', marginRight: 25}}>
            <span className="required">*</span>
            {'是否備有黑色正式裝\n'}
            <span style={{fontSize: 10}}>
              (白色長袖襯衫+黑褲/裙+黑西裝外套+黑皮鞋+黑娃娃鞋)
            </span>
          </label>

          <Radio.Group {...getRadioGroupProps('formal_wear')}>
            <Radio.Button value={true}>是</Radio.Button>
            <Radio.Button value={false}>否</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>服裝備註</label>
          <Input.TextArea {...getInputProps('formal_wear_note')} />
        </div>

        <div className="field">
          <label>
            <span className="required">*</span>餐飲習慣
          </label>
          <Radio.Group {...getRadioGroupProps('diet')}>
            <Radio.Button value={true}>葷</Radio.Button>
            <Radio.Button value={false}>素</Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <label>餐飲備註</label>
          <Input.TextArea {...getInputProps('diet_note')} />
        </div>
      </section>

      <section>
        <h3>近況更新</h3>

        <div className="field">
          <label>目前近況</label>
          <Radio.Group {...getRadioGroupProps('recent_status')}>
            <Radio.Button value="求學中" style={{marginRight: 10}}>
              求學中
            </Radio.Button>
            <Radio.Button value="待業中" style={{marginRight: 10}}>
              待業中
            </Radio.Button>
            <Radio.Button value="等當兵" style={{marginRight: 10}}>
              等當兵
            </Radio.Button>
            <Radio.Button value="其他" style={{marginRight: 10}}>
              其他
            </Radio.Button>
          </Radio.Group>
        </div>

        <div className="field">
          <Input.TextArea
            placeholder="請填寫其他描述"
            {...getInputProps('recent_status_others')}
          />
        </div>
      </section>

      <section>
        <h3>其他</h3>

        <div className="field">
          <label>自製簡歷</label>
          <ImageEditField fieldname="custom_resume_file" record={record} />
        </div>

        <div className="field">
          <label>{'自選標籤\n(可複選)'}</label>
          <Select
            mode="multiple"
            allowClear
            style={{width: '100%'}}
            placeholder="請選擇標籤"
            defaultValue={record.user_labels}
            {...getSelectProps('user_labels')}>
            {USER_LABELS.map((label) => (
              <Select.Option value={label}>{label}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="field">
          <label style={{width: 150}}>人才天下活動得知管道</label>
          <Select style={{width: 250}} {...getSelectProps('source')}>
            <Select.Option value="搜尋引擎(如：Google、Yahoo)">
              搜尋引擎(如：Google、Yahoo)
            </Select.Option>
            <Select.Option value="人才天下官網">人才天下官網</Select.Option>
            <Select.Option value="打工趣">打工趣</Select.Option>
            <Select.Option value="Facebook、Facebook社團">
              Facebook、Facebook社團
            </Select.Option>
            <Select.Option value="YouTube">YouTube</Select.Option>
            <Select.Option value="E-mail">E-mail</Select.Option>
            <Select.Option value="親友推薦">親友推薦</Select.Option>
            <Select.Option value="人力銀行">人力銀行</Select.Option>
            <Select.Option value="學校">學校</Select.Option>
            <Select.Option value="Instagram">Instagram</Select.Option>
            <Select.Option value="Line官方帳號">Line官方帳號</Select.Option>
            <Select.Option value="批踢踢實業坊">批踢踢實業坊</Select.Option>
          </Select>
        </div>

        <div className="field">
          <label style={{width: 150}} />
          <Input placeholder="其他得知管道" {...getInputProps('source_note')} />
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > section {
    margin-bottom: 25px;
    border-bottom: 1px solid #cccccc;

    & > h3 {
      color: #4a4a4a;
      margin: 0px 0px 23px 0px;
    }

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      & > label {
        margin-right: 10px;
        min-width: 80px;
        color: #4a4a4a;
        white-space: pre;

        & > .required {
          margin-right: 5px;
          color: #ff0000;
        }
      }

      & > .tag {
        border-radius: 4px;
        padding: 5px 12px;
        margin-right: 10px;
      }

      & > .select-wrapper {
        margin-right: 10px;
      }

      & > input {
        flex: 1;
      }

      & > .img-grid {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        & > figure {
          border-radius: 4px;
          width: 112px;
          height: 112px;
          margin: 0 8px 8px 0;
          background-color: #ccc;
        }
      }
    }
  }
`;

export default ResumeForm;
