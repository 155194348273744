import React from 'react';
import styled from 'styled-components';
import {Button, Modal} from 'antd';

import * as AppContext from '../../AppContext';
import {Edit, BellOutlined} from '../../Widgets/Icon';
import ResumeDisplayDetail from './MemberResume.DisplayDetail';
import ResumeEditDetail from './MemberResume.EditDetail';

function MemberMgrPage(props) {
  const app = React.useContext(AppContext.Context);
  const [profile, setProfile] = React.useState(null);
  const [visibleModal, setVisibleModal] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      app.actions.setLoading(true);
      try {
        setProfile(await app.state.member);
      } catch (err) {
        console.warn(err);
      }
      app.actions.setLoading(false);
    }

    fetchData();
  }, [app.actions, app.state.member]);

  function dismissModal() {
    setVisibleModal(false);
  }

  return (
    <Wrapper>
      <div className="notify">
        <Button
          type="primary"
          onClick={() => setVisibleModal(true)}
          style={{marginRight: 10}}>
          <Edit color={'#FFFFFF'} />
          <span>編輯履歷</span>
        </Button>
        <Button
          type="outline"
          onClick={() => app.actions.setMemberNotifications([])}>
          <BellOutlined color="rgba(0, 0, 0, 0.65)" />
        </Button>
        <div className="badge" />
      </div>

      <div className="content">
        {profile && <ResumeDisplayDetail record={profile} />}
      </div>

      <Modal
        title={null}
        footer={null}
        width={760}
        bodyStyle={{
          padding: 0,
          maxHeight: 650,
          height: '100%',
          overflow: 'auto',
        }}
        visible={visibleModal}
        onOk={dismissModal}
        onCancel={dismissModal}>
        <ResumeEditDetail record={profile} onCancel={dismissModal} />
      </Modal>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 60px 40px;
  background-color: white;

  & > .notify {
    display: flex;
    justify-content: flex-end;
    position: relative;

    & > .badge {
      position: absolute;
      top: -4px;
      right: -4px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #ec8e25;
    }
  }

  & > .content {
    max-width: 600px;
    margin: 0 auto;
  }
`;

export default MemberMgrPage;
