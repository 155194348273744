import React from 'react';
import styled from 'styled-components';
import {Tabs, Button} from 'antd';
import moment from 'moment';

import {
  INITIAL_FIELDS,
  REQUIRD_FIELDS,
  FILE_UPLOAD_FIELDS,
} from '../../../content/resume';
import * as AppContext from '../../AppContext';
import {
  DaysDiffByYear,
  onValidateResumeRequiredFields,
} from '../../Utils/index';
import {BookOutlined, MoneyCollectOutlined} from '../../Widgets/Icon';
import Avatar from './MemberResume.Avatar';
import ResumeForm from './ResumeForm';
import AdministrationForm from './AdministrationForm';

function ResumeDetail(props) {
  const app = React.useContext(AppContext.Context);
  const {record, onCancel} = props;
  const [values, setValues] = React.useState({
    ...INITIAL_FIELDS,
    ...record,
  });
  const [selectTabKey, setSelectTabKey] = React.useState('1');

  const getInputProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const getCheckboxProps = (field, boolean = true) => {
    return {
      checked: boolean ? values[field] : !values[field],
      onChange: (e) => {
        if (boolean) {
          setValues({...values, [field]: e.target.checked});
        } else {
          setValues({...values, [field]: !e.target.checked});
        }
      },
    };
  };

  const getSelectProps = (field) => {
    return {
      value: values[field],
      onChange: (value) => {
        setValues({...values, [field]: value});
      },
    };
  };

  const getDatePickerProps = (field) => {
    return {
      placeholder: `${moment(new Date()).format('YYYY-MM-DD')}`,
      value: !values[field] ? values[field] : moment(values[field]),
      onChange: (value) => {
        const date = moment(value).format('YYYY-MM-DD');

        if (value === null) {
          setValues({...values, [field]: ''});
        } else if (field === 'birthday') {
          const today = moment(new Date()).format('YYYY-MM-DD');
          setValues({
            ...values,
            [field]: date,
            age: DaysDiffByYear(today, date),
          });
        } else {
          setValues({...values, [field]: date});
        }
      },
    };
  };

  const getDateRangePickerProps = (field) => {
    return {
      placeholder: ['開始日期', '結束日期'],
      value:
        values[field].length <= 0
          ? values[field]
          : [moment(values[field][0]), moment(values[field][1])],
      onChange: (value) => {
        if (value === null) {
          setValues({...values, [field]: []});
        } else {
          setValues({...values, [field]: value});
        }
      },
    };
  };

  const getRadioGroupProps = (field) => {
    return {
      value: values[field],
      onChange: (e) => {
        setValues({...values, [field]: e.target.value});
      },
    };
  };

  const onResetValues = () => {
    setValues({...record});
  };

  const onSubmit = async ({publish}) => {
    if (!onValidateResumeRequiredFields(values) && !publish) {
      alert('請先填寫必填項目，才能使用暫時存檔功能。：）');
      return;
    }

    if (!onValidateResumeRequiredFields(values) && publish) {
      alert('請先填寫必填項目，才能完成送出喔。：）');
      return;
    }

    app.actions.setLoading(true);
    try {
      FILE_UPLOAD_FIELDS.forEach((element) => {
        delete values[element];
      });
      await app.actions.memberUpdateProfile({...values, publish});
      onCancel();
    } catch (err) {
      throw err;
    }

    app.actions.setLoading(false);
  };

  React.useEffect(() => {}, [record.avatar]);

  return (
    <Wrapper>
      <Avatar record={record} />

      <Tabs
        defaultActiveKey={selectTabKey}
        size="large"
        onChange={(activeKey) => setSelectTabKey(activeKey)}>
        <Tabs.TabPane
          key="1"
          tab={
            <>
              <BookOutlined
                size={16}
                color={selectTabKey === '1' ? '#E18135' : '#808080'}
              />
              <span
                style={{
                  color: selectTabKey === '1' ? '#E18135' : '#808080',
                }}>
                我的履歷
              </span>
            </>
          }>
          <ResumeForm
            record={values}
            getInputProps={getInputProps}
            getCheckboxProps={getCheckboxProps}
            getSelectProps={getSelectProps}
            getDatePickerProps={getDatePickerProps}
            getDateRangePickerProps={getDateRangePickerProps}
            getRadioGroupProps={getRadioGroupProps}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="2"
          tab={
            <>
              <MoneyCollectOutlined
                size={16}
                color={selectTabKey === '2' ? '#E18135' : '#808080'}
              />
              <span
                style={{
                  color: selectTabKey === '2' ? '#E18135' : '#808080',
                }}>
                行政項目
              </span>
            </>
          }>
          <AdministrationForm record={record} getInputProps={getInputProps} />
        </Tabs.TabPane>
      </Tabs>

      <div className="action-bar">
        <Button type="dashed" onClick={() => onSubmit({publish: false})}>
          暫時存檔
        </Button>

        <div className="action-bar-right">
          <Button
            type="dashed"
            onClick={onResetValues}
            style={{marginRight: 20}}>
            重新填寫
          </Button>
          <Button type="primary" onClick={() => onSubmit({publish: true})}>
            完成送出
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;

  & > .action-bar {
    display: flex;
    justify-content: space-between;

    & > .action-bar-right {
      display: flex;
    }
  }
`;
export default ResumeDetail;
