import React from 'react';
import styled from 'styled-components';
import {Input, Select, Checkbox, DatePicker, Radio} from 'antd';

import * as AppContext from '../../AppContext';
import {ImageEditField} from './MemberResume.ImageField';

function AdministrationForm(props) {
  let {record, getInputProps} = props;
  return (
    <Wrapper edit={record.INTERVIEW_STATE === 'pass'}>
      <div className="mask" />
      <div className="hint">
        {`# 提示 : 會員您好，因應完成活動後之相關發薪作業需要，煩請提供下列文件以利完成後續發薪作業。\n-「身分證或居留證正反面影本」\n-「郵局或中國信託本人台幣帳戶存摺封面影本」\n(如兩者皆無，務必提供其他家金融機構之存摺封面影本)\n\n# 注意事項 : ＊帳戶存摺封面需有分行資訊 ＊其他家金融機構另收手續費30元\n人才天下有限公司感謝您的配合！ ：）`}
      </div>

      <section>
        <div className="field">
          <label>郵局立帳局號</label>
          <Input {...getInputProps('post_office_number')} />
        </div>

        <div className="field">
          <label>郵局存簿帳號</label>
          <Input {...getInputProps('post_office_account')} />
        </div>

        <div className="field">
          <label>銀行解款行代號</label>
          <Input {...getInputProps('bank_code')} />
        </div>

        <div className="field">
          <label>銀行分行代號</label>
          <Input {...getInputProps('bank_branch_code')} />
        </div>

        <div className="field">
          <label>銀行別-分行名稱</label>
          <Input {...getInputProps('bank_account_note')} />
        </div>

        <div className="field">
          <label>銀行帳號</label>
          <Input {...getInputProps('bank_account')} />
        </div>

        <div className="field">
          <label>存摺封面照片</label>
          <ImageEditField fieldname="bank_passbook_file" record={record} />
        </div>

        <div className="field">
          <label>合約回簽照片</label>
          <ImageEditField fieldname="contract_file" record={record} />
        </div>

        <div className="field">
          <label>身份證、居留證正面照片</label>
          <ImageEditField fieldname="id_number_front_file" record={record} />
        </div>

        <div className="field">
          <label>身份證、居留證反面照片</label>
          <ImageEditField fieldname="id_number_back_file" record={record} />
        </div>

        <div className="field">
          <label>工作簽正面照片</label>
          <ImageEditField fieldname="work_permit_front_file" record={record} />
        </div>

        <div className="field">
          <label>工作簽反面照片</label>
          <ImageEditField fieldname="work_permit_back_file" record={record} />
        </div>

        <div className="field">
          <label>法定代理人證書回簽照片</label>
          <ImageEditField fieldname="legal_agent_file" record={record} />
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;

  & > .mask {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100000;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    display: ${(props) => (props.edit ? 'none' : 'block')};
  }

  & > .hint {
    margin-bottom: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 15px;
    white-space: pre;
    line-height: 20px;
    font-size: 12px;
    color: gray;
  }

  & > section {
    margin-bottom: 25px;

    & > .field {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      & > label {
        width: 100px;
        color: #4a4a4a;
      }

      & > input {
        flex: 1;
      }
    }
  }
`;

export default AdministrationForm;
