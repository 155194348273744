import React from 'react';
import styled from 'styled-components';
import {Button, Upload, Spin} from 'antd';

import * as AppContext from '../../AppContext';
import {UserOutlined, CameraFilled} from '../../Widgets/Icon';

function Avatar(props) {
  const app = React.useContext(AppContext.Context);
  const {record} = props;
  const {avatar} = record;
  const [values, setValues] = React.useState({
    loading: false,
    url: null,
    data: {},
    filename: null,
  });

  const beforeUpload = async (file) => {
    try {
      const resp = await app.actions.memberCreateImageRequest(file);
      setValues({
        ...values,
        url: resp.url,
        data: resp.fields,
        filename: resp.expected,
      });
    } catch (err) {
      console.warn('upload file failed', err);
    }
  };

  const handleChange = async (info) => {
    if (info.file.status === 'uploading') {
      setValues({...values, loading: true});
    }
    if (info.file.status === 'done') {
      try {
        await app.actions.memberUpdateProfile({
          ...record,
          publish: false,
          avatar: values.filename,
        });
      } catch (err) {
        console.warn('update profile failed', err);
      }

      setValues({...values, loading: false});
    }
  };
  return (
    <Wrapper>
      <Upload
        name="file"
        className="avatar-uploader container"
        listType="picture"
        showUploadList={false}
        action={values.url}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        data={values.data}>
        {values.loading ? (
          <Spin />
        ) : avatar ? (
          <BgImage url={avatar}>
            <Button type="primary" shape="circle" icon={<CameraFilled />} />
          </BgImage>
        ) : (
          <BgImage>
            <UserOutlined color="#FFFFFF" size={80} />
            <Button shape="circle" type="primary" icon={<CameraFilled />} />
          </BgImage>
        )}
      </Upload>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 34px;
  height: 200px;

  & > .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const BgImage = styled.div`
  width: 160px;
  height: 160px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => (props.url ? '' : 'lightgray')};
  background-image: url(${(props) => props.url || ''});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  & button {
    position: absolute;
    right: 2px;
    bottom: -4px;
  }
`;

export default Avatar;
